import store from "../../store/index";

// TODO: Return local or online URL
export function getImageData(folder, imageName) {
  try {
    return store.getters.getImageData({ file: `${folder.toUpperCase()}/${imageName}`, lang: store.getters['user/getSelectedLanguage'] })
  } catch (e) {
    console.log(`Error fetching image data: ${e.message}`);
    return getImagePlaceholder();
  }
}

export function getImagePlaceholder() {
  return require('../../assets/images/image-placeholder.jpg');
}

// TODO: Return local or online URL
export function getFileURL(folder, fileName) {
  try {
    let result = store.getters.getFileName({
      file: folder.toUpperCase() + '/' + fileName,
      lang: store.getters['user/getSelectedLanguage']
    })
    return `file://${result}`
  } catch (e) {
    console.log(`Error fetching file URL: ${e.message}`);
    return '';
  }
}

export function openWindow (url, mimeType, filename) {
  var xhr = new XMLHttpRequest();
  xhr.open('get', url);
  xhr.responseType = 'blob';
  xhr.onload = (e) => {
    if (xhr.status != 200) return;
    let blob = xhr.response;
    if (filename) blob.name = filename;
    if (mimeType) blob = new Blob([blob], { type: mimeType });

    let a = document.createElement('a');
    a.target = '_blank';
    a.href = URL.createObjectURL(blob);
    a.click();
  };
  xhr.send();
}

export default { getImageData, getImagePlaceholder, getFileURL, openWindow };
