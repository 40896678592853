<template>
  <div id="todays-class-page">

    <!-- Header -->
    <div class="page-header todays-class-header row w-100 align-items-end mx-0 mb-4">
      <div class="title col-12 col-md-8 mx-auto pb-2">
        <h3 class="text-white">{{ $t("telestia.title.todays_class") }}</h3>
      </div>
    </div>

    <!-- Loading state -->
    <div v-if="pendingComponentInit" class="loading-state d-flex justify-content-center">
      <b-spinner style="width: 3rem; height: 3rem;" type="grow" variant="primary" />
    </div>

    <!-- Loaded state -->
    <template v-else>

      <!-- Error -->
		  <div v-if="error" class="row mx-0">
		    <div class="col-12 col-md-8 offset-md-2">
		      <h4 class="mb-2">{{ error }}</h4>
		    </div>
		  </div>

      <!-- Today's class -->
		  <div v-else class="row mx-0">
		    <div class="col-12 col-md-8 mx-md-auto">

          <div class="row mx-0">
            <div class="col-6 mx-md-auto">
              <h3>{{ currentCourseTitle }}</h3>
            </div>
            <div class="col-6 mx-md-auto">
              <div class="row mx-0">
                <label class="col-sm-4 col-form-label" :class="isRtl?'text-left':'text-right'">{{ $t('telestia.progress.search') }}</label>
                <div class="col-sm-8 position-relative">
                  <input type="text" class="form-control" v-model="searchTerm">
                  <span class="btn btn-link position-absolute pos-r-2 pos-t-0" :class="{ 'd-none' : !searchTerm }" @click="searchTerm=''">
                    <font-awesome-icon :icon="['fas', 'times']" size="lg"/>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <!-- Packages -->
          <template v-for="pack in packagesFiltered">
            <!-- Title & PDF -->
				    <div class="row mt-5" :key="`${pack.packageId}-1`">
				      <div class="col d-flex mb-3">
				        <h5>{{ pack.title }}</h5>

				        <div class="d-flex ml-auto" v-if="pack.pdf">
						      <h5 data-toggle="tooltip" data-placement="top">{{ $t("telestia.title.learning_package") }}:</h5>
				        	<font-awesome-icon
				        		:icon="['fas', 'box']"
				        		:title="$t('telestia.hover_text.download_lp')"
				        		size="lg"
				        		class="mouse-pointer ml-2 text-blue"
				        		@click="downloadPackagePdf(pack.pdf)"/>
				        </div>
				      </div>
				    </div>

            <!-- Lessons -->
            <div class="row mb-5" :key="`${pack.packageId}-2`">
              <div class="col">
                <div class="table-responsive">
                  <table class="table align-middle">
                    <thead>
                      <tr>
                        <th>{{ $t("telestia.table.header.lesson") }}</th>
                        <template v-if="!isOfflineBuild">
                          <th class="text-center">{{ $t("telestia.table.header.studied_on") }}</th>
                          <th class="text-center">{{ $t("telestia.table.header.last_time") }}</th>
                          <th class="text-center">{{ $t("telestia.table.header.times") }}</th>
                          <th class="text-center">{{ $t("telestia.table.header.completed_on") }}</th>
                          <th class="text-center">{{ $t("telestia.table.header.tutor_assignment") }}</th>
                        </template>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="lesson in lessonsFiltered(pack.lessons)" :key="lesson.code" :class="{ 'next-class': nextLessonToStudy.id && nextLessonToStudy.id === lesson.id }">
                        <td>
                          <small class="text-muted">
                            {{ lesson.menu1.title }}<span class="text-nowrap">&nbsp;<font-awesome-icon :icon="['fas', isRtl ? 'chevron-left' : 'chevron-right']" /></span>
                            {{ lesson.menu2.title }}<span class="text-nowrap">&nbsp;<font-awesome-icon :icon="['fas', isRtl ? 'chevron-left' : 'chevron-right']" /></span>
                          </small><br>
                          <span class="text-link mouse-pointer text-underline"
                                @click="selectLesson({ menu1Index: lesson.menu1.index, menu2Index: lesson.menu2.index, lessonKey: lesson.code }, lesson.id)">
                            {{ lesson.title }}
                          </span>
                        </td>
                        <template v-if="!isOfflineBuild">
                          <td class="text-center">
                            <span v-if="lesson.studiedOn">{{ lesson.studiedOn | formatDate }}</span>
                            <span v-else>--</span>
                          </td>
                          <td class="text-center">
                            <span v-if="lesson.lastStudiedOn">{{ lesson.lastStudiedOn | formatDate }}</span>
                            <span v-else>--</span>
                          </td>
                          <td class="text-center">{{ lesson.timesStudied ? lesson.timesStudied : '--' }}</td>
                          <td class="text-center">
                            <button
                                v-if="!lesson.completedOn"
                                class="primary-btn btn-sm"
                                :class="{ 'disabled-btn': !lesson.timesStudied }"
                                @click="markAsComplete(lesson.id)"
                                data-toggle="tooltip"
                                data-placement="top"
                                :title="$t('telestia.hover_text.mark_as_complete')">
                              {{ $t("telestia.button.complete") }}
                            </button>
                            <span v-else>{{ lesson.completedOn | formatDate }}</span>
                          </td>
                          <td class="text-center">
                            <span v-if="lesson.tutorAssignment">
                              <font-awesome-icon
                                v-if="hasAssignmentInstructions(lesson.code)"
                                class="view-assignment-icon text-blue mouse-pointer"
                                :icon="['fas', 'search-plus']"
                                size="sm"
                                v-b-modal.assignment-viewer-modal
                                @click="fetchAssignmentInstructions(lesson.code)"
                              ></font-awesome-icon>
                              <font-awesome-icon class="text-blue" v-else :icon="['fas', 'check']" size="sm" ></font-awesome-icon>
				                    </span>
                            <span v-else>--</span>
                          </td>
                        </template>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

				  </template>

          <!-- Pseudo lessons from affiliate -->
          <div v-if="!isOfflineBuild && !!Object.keys(orgAssignments).length">
            <div class="row mt-5">
              <div class="col d-flex mb-3">
                <h5>{{ currentCourseTitle }}</h5>
              </div>
            </div>
            <div class="row mb-5">
              <div class="col">
                <div class="table-responsive">
                  <table class="table align-middle">
                    <thead>
                      <tr>
                        <th>{{ $t("telestia.table.header.lesson") }}</th>
                        <th class="text-center">{{ $t("telestia.button.open") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(link, title) in orgAssignments" :key="title">
                        <td style="width:100%">
                          <small class="text-muted">
                            {{ currentCourseTitle }} <font-awesome-icon :icon="['fas', isRtl ? 'chevron-left' : 'chevron-right']" />
                          </small><br>
                          <a class="text-link mouse-pointer text-underline" target="_blank" :href="`${link}?lang=${selectedLanguage}`">
                            {{ $t(title) }}
                          </a>
                        </td>
                        <td class="text-center">
                          <a v-if="completedCourse" :href="`${link}?lang=${selectedLanguage}`" target="_blank" class="primary-btn btn-sm">
                            {{ $t("telestia.button.open") }}
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

		  	</div>
		  </div>

      <!-- Assignment preview -->
			<b-modal v-if="showAssignment" id="assignment-viewer-modal" centered hide-footer hide-header @hidden="assignmentFile = ''">
        <div v-if="pendingAssignmentFetch" class="loading-state d-flex justify-content-center">
          <b-spinner style="width: 3rem; height: 3rem;" type="grow" variant="primary" />
        </div>

        <template v-else>
          <img v-if="assignmentFile" :src="assignmentFile" alt="upload-image" class="img-fluid" />
        </template>
		  </b-modal>
		</template>
  </div>
</template>

<script>
import assets from "../../shared/helpers/assets";
import _groupBy from 'lodash/groupBy';
import _isEmpty from 'lodash/isEmpty';
import _orderBy from 'lodash/orderBy';
import envConfig from '../../shared/configs/config.local.js';

const FILE_SERVER = envConfig.FILE_SERVER;

export default {
  name: "lessons-progress",

  data() {
    return {
      pendingComponentInit: false,
      pendingAssignmentFetch: false,
      fetchAssigmentError: '',

      packages: [],
      error: '',
      showAssignment: false,
      assignmentFile: '',

      searchTerm: '',
    }
  },

  created() {
    this.fetchTodaysClass();
  },

  computed: {
    isOfflineBuild() { return this.$store.getters['app/isOffline'] },

    isOnlineBuild() { return this.$store.getters['app/isOnline'] },

    isRtl() { return this.$store.getters['app/isRtl']; },

    selectedLanguage() { return this.$store.getters['user/getSelectedLanguage']; },

    orgAssignments() { return (this.$store.getters['userOrganisation/getOrganisation']).assignments },

    completedCourse() { return (this.$store.getters['progress/getCurrentCourseState']).finished },

    // Computed property that provides the next lesson to study based on the user's progress
    nextLessonToStudy() {
    	return this.$store.getters["progress/getNextToStudy"];
    },

    packagesFiltered(){ return this.packages.filter((pack) => !!this.lessonsFiltered(pack.lessons).length) },

  },

  methods: {
    // Fetch Today's class report
    async fetchTodaysClass() {
      try {
        this.pendingComponentInit = true;

        if (this.isOfflineBuild) {
          this.packages = this.$store.getters.getCoursePackages;
          return;
        }

        this.currentCourseTitle = await this.getCurrentCourseTitle();
        const currentCourseId = this.$store.getters["userCourses/getCourseId"](this.$store.getters.getSelected.course);
        const todaysClass = await this.$store.dispatch("progress/fetchTodaysClass", currentCourseId);
        // const assignments = await this.$store.dispatch("userAssignments/fetchUserAssignmentsByCourseId", currentCourseId);
        const lpackagesOrder = this.$store.getters.getCoursePackagesOrder;

        let packages = _groupBy(todaysClass, 'package_id');
        Object.keys(packages).forEach(packageId => {
          const lessons = packages[packageId];
          packages[packageId] = {};
					packages[packageId].packageId = packageId;
					packages[packageId].order = lpackagesOrder.findIndex(pkgId => pkgId === parseInt(packageId));
          packages[packageId].title = lessons[0].packagetitle;
          packages[packageId].pdf = lessons[0].pdf;
          packages[packageId].lessons = lessons.map(l => {
            const lessonCode = this.$store.getters.getLessonCode(l.id);
            const menuInfo = this.$store.getters.getLessonMenuEntries(lessonCode)
            return {
              ...menuInfo,
              title: l.title || menuInfo.title,
              id: l.id,
              studiedOn: l.first_time,
              lastStudiedOn: l.last_time,
              timesStudied: l.times,
              completedOn: l.completed,
              // tutorAssignment: l.assignment ? { isUploaded: !!assignments.find(a => a.lesson_id === l.id) } : false
              tutorAssignment: l.assignment === 1
            }
          });
          packages[packageId].lessons.filter(l => l.code && l.title);
        })
				packages = _orderBy(packages, 'order');
        this.packages = packages;

        await this.fetchProgressForSelectedCourse(currentCourseId);
      } catch (e) {
        console.log(e);
        //could not fetch progress
        if (!this.isOnlineBuild) {
          //if it is not the online app (hybrid), get the offline list
          this.packages = this.$store.getters.getCoursePackages;
        } else {
          this.showErrorAlert({
            title: this.$t('telestia.error.title'),
            text: this.$t('telestia.error.course_progress') + "\n" + this.$t('telestia.error.connection'),
          });
          this.error = this.$t('telestia.error.course_progress') + ' ' + this.$t('telestia.error.connection');
        }
      } finally {
        this.pendingComponentInit = false;

        // Scroll to current lesson
        this.$nextTick(() => {
          let el = document.querySelector('tr[class~="next-class"]');
          if (el) el.scrollIntoView({ block: 'center', behavior: 'smooth' });
        });
      }
    },

    // Returns current course title
    async getCurrentCourseTitle() {
      let currentCourseTitle = ''
      if (!this.isOnlineBuild) {
        try {
          const products = await this.$store.dispatch('getProductCourses')
          products.forEach(prod => {
            const selectedCourse = prod.courses.find(c => c.ckey === this.$store.getters.getSelected.course)
            if (selectedCourse) {
              currentCourseTitle = selectedCourse.ctitle
            }
          })
          return Promise.resolve(currentCourseTitle);
        } catch (e) {
          return Promise.reject(e);
        }
      }

      const currentCourseKey = this.$store.getters.getSelected.course;
      currentCourseTitle = this.$store.getters.getCourseTitle(currentCourseKey);
      return Promise.resolve(currentCourseTitle);
    },

    // Fetch progress for selected course (to be used in order to restrict access and highlight next lesson to study);
    async fetchProgressForSelectedCourse(currentCourseId) {
  		try {
				await this.$store.dispatch("progress/fetchLastNextToStudy", currentCourseId);
				return Promise.resolve();
  		} catch (e) {
  		  console.log(e);
        this.showErrorAlert({
          title: this.$t('telestia.error.title'),
          text: this.$t('telestia.error.coruse_progress') + "\n" + this.$t('telestia.error.connection'),
        });
  			return Promise.resolve();
  		}
  	},

  	hasAssignmentInstructions(lessonKey) {
  		return this.$store.getters.hasAssignmentInstructionsImage(lessonKey);
  	},

		// Fetch assignment instructions file
    async fetchAssignmentInstructions(lessonKey) {
      try {
        this.showAssignment = true;
        this.pendingAssignmentFetch = true;

				if (lessonKey.includes('_ml')) { lessonKey = lessonKey.substring(0, lessonKey.length - 3); }
        this.assignmentFile = await this.$store.dispatch("userAssignments/fetchAssignmentInstructions", lessonKey);
      } catch (e) {
      	this.showAssignment = false;

      	console.log(e);
        if (e.response.status === 404) {
          this.showErrorAlert({
            title: this.$t('telestia.error.title'),
            text: this.$t('telestia.error.ass_instructions_notfound') + "\n" + this.$t('telestia.error.connection'),
          });
        } else {
          this.showErrorAlert({
            title: this.$t('telestia.error.title'),
            text: this.$t('telestia.error.ass_instructions') + "\n" + this.$t('telestia.error.connection'),
          });
    		}
      } finally {
        this.pendingAssignmentFetch = false;
      }
    },

    // Mark selected lesson as complete
    async markAsComplete(id) {
    	try {
        await this.$store.dispatch("progress/markLessonAsComplete", id);

        for (let pack of this.packages) {
        	const lessonToComplete = pack.lessons.find(l => l.id === id);
        	if (lessonToComplete) {
        		lessonToComplete.completedOn = new Date().toISOString();
        		break;
        	}
        }
      } catch (e) {
        console.log(e);
        this.showErrorAlert({
          title: this.$t('telestia.error.title'),
          text: this.$t('telestia.error.lesson_complete') + "\n" + this.$t('telestia.error.connection'),
        });
      }
    },

    // Download package pdf
    downloadPackagePdf(pdfName) {
      const selectedProduct = this.$store.getters.getSelected.productKey;
      assets.openWindow(this.isOnlineBuild
        ? `${FILE_SERVER}/assets?product=${selectedProduct.toUpperCase()}&title=${pdfName}&language=${this.selectedLanguage}`
        : assets.getFileURL(selectedProduct, pdfName), 'application/pdf', pdfName
      );
    },

    // Select lesson if user is allowed to view it
    selectLesson(items, lessonId) {
      if (!this.$store.getters.isAllowedToView(lessonId)) {
        this.showErrorAlert({
          title: '',
          text: this.$t('telestia.error.not_active') + `"${this.nextLessonToStudy.menu.title}"` + "\n" + this.$t('telestia.error.check_todaysclass'),
        });
        return;
      }
      this.selectMenu(items);
      const selected = this.$store.getters.getSelected;
      this.$router.push({ name: 'App:Lessons:Lesson', params: { product_key: selected.productKey.toLowerCase(), course_key: selected.course.toLowerCase(), lesson_id: lessonId } });
    },

    // items is an object with possible properties: menu1Index, menu2Index, lessonKey
    selectMenu(items) {
      const productSkeleton = this.$store.getters["products/getCurrentProductSkeleton"];
      const payload = _isEmpty(productSkeleton) ? items : { ...items, productSkeleton  };
      this.$store.commit('selectMenu', payload);
    },

    showErrorAlert({ title, text }) {
    	this.$swal({ title, text, icon: 'error', timer: 5000, button: true });
    },

    lessonsFiltered(lessons){ return lessons?.filter((lesson) => !!lesson.title.toLowerCase().includes(this.searchTerm.toLowerCase())) },
  },

  watch: {
		selectedLanguage() {
			this.packages.forEach(p =>
				p.lessons.forEach(l => {
					const lessonCode = this.$store.getters.getLessonCode(l.id);
        	const menuInfo = this.$store.getters.getLessonMenuEntries(lessonCode);

      		l.menu1.title = menuInfo.menu1.title;
      		l.menu2.title = menuInfo.menu2.title;
      		l.title = menuInfo.title;
				})
			)
		}
  }
}
</script>

<style scoped>
.disabled-btn {
	opacity: 0.5;
	pointer-events: none;
}
.primary-btn {
  text-decoration: none;
}

.text-link {
  color: black;
}
.text-link:hover {
  color: #1b96cf;
}
.todays-class-header {
  background-image: url(../../assets/images/page-header/progress.png);
}
.next-class {
	background-color: lightgreen;
}
</style>

